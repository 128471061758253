import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import { Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHistory,
  faBolt,
  faCircleInfo,
  faBook,
  faDiagramProject,
  faSquare
} from '@fortawesome/free-solid-svg-icons';
import { Range, getTrackBackground } from 'react-range';
import Switch from 'react-switch';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Seo } from '../modules/common/components/seo';
import lodash from 'lodash';
import { SpecialitiesArray } from '../modules/cs/employee/model/specialities-array';
import { StatCalculator } from '../modules/cs/employee/utils/stat-calculator';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { IEmployeeNode } from '../modules/common/model/graphql-types';
import { RatingBox } from '../modules/common/components/rating-box';

import './employee.scss';

const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p>{children}</p>;

const options = {
  renderMark: {
    ['MARKS.BOLD']: (text) => <Bold>{text}</Bold>
  },
  renderNode: {
    ['BLOCKS.PARAGRAPH']: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

const SpecialityItem = ({ speciality }) => {
  const specialityObj = lodash.find(SpecialitiesArray, { value: speciality });
  if (specialityObj) {
    const popoverSpecialities = (
      <Popover id="popover-specialities">
        <Popover.Header as="h3">{specialityObj.label}</Popover.Header>
        <Popover.Body>{specialityObj.description}</Popover.Body>
      </Popover>
    );
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="auto-start"
        overlay={popoverSpecialities}
      >
        <div className="employee-role">{specialityObj.label}</div>
      </OverlayTrigger>
    );
  } else {
    return <span>{speciality}</span>;
  }
};

interface IEmployeeNodes {
  nodes: IEmployeeNode[];
}

interface IEmployeeEntry {
  currentUnit: IEmployeeNodes;
}

interface IProps {
  data: IEmployeeEntry;
}

const EmployeeDetails: React.FC<IProps> = ({ data }) => {
  const employee = data.currentUnit.nodes[0];
  const [statsMode, setStatsMode] = useState(true);
  const [statsLevel, setStatsLevel] = useState([100]);
  const [employeeStats, setEmployeeStats] = useState(null);
  const [statsIncludeLoyalty, setStatsIncludeLoyalty] = useState(false);
  const [currentTab, setCurrentTab] = useState('Info');
  const [currentColor, setCurrentColor] = useState('#FFFFFF');

  useEffect(() => {
    if (employee.rarityClassName === 'R') {
      setCurrentColor('#2ca7ff');
    } else if (employee.rarityClassName === 'SR') {
      setCurrentColor('#c97ef5');
    } else if (employee.rarityClassName === 'SSR') {
      setCurrentColor('#D7BC57');
    } else if (employee.rarityClassName === 'ASSR') {
      setCurrentColor('#E16B20');
    }
  }, []);

  const profileImage = employee.smallAvatar
    ? getImage(employee.smallAvatar.localFile.childImageSharp)
    : null;

  useEffect(() => {
    if (employee && statsLevel) {
      setEmployeeStats(
        StatCalculator(employee, statsLevel[0], statsIncludeLoyalty)
      );
    }
  }, [statsLevel, employee, statsIncludeLoyalty]);

  const employeeType = employee.type
    .toString()
    .split(',')
    .map((type, index) => (
      <div className={`type ${index === 1 ? 'second' : 'first'}`} key={index}>
        {type === 'Counter' && (
          <StaticImage
            src="../images/counterside/icons/type_counter.png"
            alt="Counter"
          />
        )}
        {type === 'Mech' && (
          <StaticImage
            src="../images/counterside/icons/type_mech.png"
            alt="Mech"
          />
        )}
        {type === 'Soldier' && (
          <StaticImage
            src="../images/counterside/icons/type_soldier.png"
            alt="Soldier"
          />
        )}
        {type === 'Corrupted Object' && (
          <StaticImage
            src="../images/counterside/icons/type_co.png"
            alt="Corrupted Object"
          />
        )}
        <strong>{type}</strong>
      </div>
    ));

  return (
    <DashboardLayout className={'generic-page character-page-cs'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/characters">Characters</Link>
        </li>
        <li className="divider">/</li>
        <li>{employee.fullName}</li>
      </ul>
      <>
        <div className="character-top">
          <div className="left-info">
            <h1>
              <span className="small"></span>
              <strong className={`rarity-${employee.rarityClassName}`}>
                {employee.fullName}
              </strong>
              <span className="sub">Information and Guide</span>
            </h1>
          </div>
          <div className="right-image">
            <div
              className={`character-background rarity-${employee.rarityClassName} ${employee.slug}`}
            ></div>
            <GatsbyImage image={profileImage} alt="Character" />
          </div>
        </div>
        <Row className="intro-section">
          <Col xs={12} xl={12}>
            <div
              className={`content-header rarity-${employee.rarityClassName}`}
            >
              <FontAwesomeIcon icon={faSquare} width="18" /> Introduction
            </div>
            <div className="character-intro">
              <div className="combined">
                <h2>
                  <strong className={`rarity-${employee.rarityClassName}`}>
                    {employee.fullName}
                  </strong>{' '}
                  is a{' '}
                  <strong
                    className={`color-rarity ${employee.rarityClassName}`}
                  >
                    {employee.rarityClassName}
                  </strong>{' '}
                  Rarity character from the{' '}
                  {employee.role === 'Defender' && (
                    <StaticImage
                      src="../images/counterside/icons/role_defender.png"
                      alt={employee.role}
                    />
                  )}
                  {employee.role === 'Ranger' && (
                    <StaticImage
                      src="../images/counterside/icons/role_ranger.png"
                      alt={employee.role}
                    />
                  )}
                  {employee.role === 'Siege' && (
                    <StaticImage
                      src="../images/counterside/icons/role_siege.png"
                      alt={employee.role}
                    />
                  )}
                  {employee.role === 'Sniper' && (
                    <StaticImage
                      src="../images/counterside/icons/role_sniper.png"
                      alt={employee.role}
                    />
                  )}
                  {employee.role === 'Striker' && (
                    <StaticImage
                      src="../images/counterside/icons/role_striker.png"
                      alt={employee.role}
                    />
                  )}
                  {employee.role === 'Supporter' && (
                    <StaticImage
                      src="../images/counterside/icons/role_support.png"
                      alt={employee.role}
                    />
                  )}
                  {employee.role === 'Tower' && (
                    <StaticImage
                      src="../images/counterside/icons/role_tower.png"
                      alt={employee.role}
                    />
                  )}{' '}
                  <strong>{employee.role}</strong> class and {employeeType}{' '}
                  type.{' '}
                  {employee.title && (
                    <>
                      They are part of the <strong>{employee.title}</strong>{' '}
                      faction.
                    </>
                  )}
                </h2>
                {employee.isItFuryUser && (
                  <div className="fury-info">
                    <p>
                      <strong className={`rarity-${employee.rarityClassName}`}>
                        {employee.fullName}
                      </strong>{' '}
                      is a <strong> Fury type user</strong>. It means that their
                      skills don't have a cooldown and instead they are used
                      every set number of Attacks instead.
                    </p>
                  </div>
                )}
                {employee.isRearmed === true &&
                  employee.originalUnitRef != null && (
                    <div className="rearm-info">
                      <p>
                        They are a{' '}
                        <Link to="/counter-side/guides/rearm-system">
                          <strong>Rearmed Character</strong>
                        </Link>{' '}
                        - if you're looking for the base version of that
                        character please check{' '}
                        <Link
                          to={
                            '/counter-side/characters/' +
                            employee.originalUnitRef.slug
                          }
                        >
                          this profile
                        </Link>
                        .
                      </p>
                    </div>
                  )}
              </div>

              <p className="hide-on-mobile">
                To learn more about{' '}
                <strong className={`rarity-${employee.rarityClassName}`}>
                  {employee.fullName}
                </strong>{' '}
                check the sections below. <strong>Use the tabs</strong> to
                quickly switch to the kind of information you're looking for.
              </p>
            </div>
          </Col>
        </Row>
        <div
          className="fuse-ad-placeholder bigger"
          data-fuse="22844297232"
        ></div>
        <p className="show-on-mobile">
          To learn more about{' '}
          <strong className={`rarity-${employee.rarityClassName}`}>
            {employee.fullName}
          </strong>{' '}
          check the sections below. <strong>Use the tabs</strong> to quickly
          switch to the kind of information you're looking for.
        </p>
        <div className="tabs">
          <div
            className={`single-tab rarity-${employee.rarityClassName} ${
              currentTab === 'Info' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Info')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
            </div>
            <p>Profile</p>
          </div>
          <div
            className={`single-tab rarity-${employee.rarityClassName} ${
              currentTab === 'Review' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Review')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faBook} width="18" />
            </div>
            <p>Review and ratings</p>
          </div>
          <div
            className={`single-tab rarity-${employee.rarityClassName} ${
              currentTab === 'Build' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Build')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faDiagramProject} width="18" />
            </div>
            <p>Other information</p>
          </div>
        </div>
        <div className={`tab-inside ${currentTab === 'Info' ? 'active' : ''}`}>
          <div className={`mobile-header rarity-${employee.rarityClassName}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Profile
            </p>
          </div>
          <div className={`content-header rarity-${employee.rarityClassName}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Combat information
          </div>
          <Row xs={1} xxl={4} className="va-section">
            <Col>
              <div className="info-list-row">
                <div className="category">Rarity</div>
                <div className="details">
                  <span className={`color-rarity ${employee.rarityClassName}`}>
                    {employee.rarityClassName}
                  </span>
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category ">Deployment Cost</div>
                <div className="details">{employee.cost} cost</div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">Movement Type</div>
                <div className="details">{employee.movementType}</div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">Attack type</div>
                <div className="details">{employee.attackType}</div>
              </div>
            </Col>
          </Row>
          <div className={`content-header rarity-${employee.rarityClassName}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Specialties
          </div>
          <div className="specialities-list">
            {employee.speciality ? (
              employee.speciality.sort().map((speciality, idx) => {
                return <SpecialityItem key={idx} speciality={speciality} />;
              })
            ) : (
              <span className="no-spec">-</span>
            )}
          </div>
          <div className={`content-header rarity-${employee.rarityClassName}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Skills
          </div>
          {employee.skillsUpdated ? (
            <>
              <div className={`skills rarity-${employee.rarityClassName}`}>
                <Row xs={1} xxl={2}>
                  {employee.skillsUpdated.map((skill, index) => {
                    return (
                      <Col key={index}>
                        <div className="box">
                          <div className="skill-header">
                            <div
                              className={`skill-icon rarity-${employee.rarityClassName}`}
                            >
                              {skill.type}
                            </div>
                            <div className="skill-info">
                              <p className="skill-name">{skill.name}</p>
                            </div>
                          </div>
                          <div className={`additional-information`}>
                            <p>
                              Valid hits:{' '}
                              <span>
                                {skill.validHits ? (
                                  <>{skill.validHits}</>
                                ) : (
                                  <>-</>
                                )}
                              </span>
                            </p>
                            <p>
                              Cooldown: <span></span>
                              {skill.cooldown ? (
                                <span>
                                  {employee.isItFuryUser ? (
                                    <FontAwesomeIcon icon={faBolt} width="18" />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faHistory}
                                      width="18"
                                    />
                                  )}{' '}
                                  {skill.cooldown}{' '}
                                  {employee.isItFuryUser
                                    ? 'basic attacks'
                                    : 'seconds'}
                                </span>
                              ) : (
                                '-'
                              )}
                            </p>
                          </div>
                          <div className={`skill-description`}>
                            <ReactMarkdown
                              children={skill.baseDescription.baseDescription}
                              remarkPlugins={[remarkGfm]}
                            />
                          </div>
                          <div className="skill-upgrades">
                            <p>
                              <span>Level 2:</span>{' '}
                              <ReactMarkdown
                                children={skill.level2.level2}
                                remarkPlugins={[remarkGfm]}
                                components={{ p: 'span' }}
                              />
                            </p>
                            <p>
                              <span>Level 3:</span>{' '}
                              <ReactMarkdown
                                children={skill.level3.level3}
                                remarkPlugins={[remarkGfm]}
                                components={{ p: 'span' }}
                              />
                            </p>
                            <p>
                              <span>Level 4:</span>{' '}
                              <ReactMarkdown
                                children={skill.level4.level4}
                                remarkPlugins={[remarkGfm]}
                                components={{ p: 'span' }}
                              />
                            </p>
                            <p>
                              <span>Level 5:</span>{' '}
                              <ReactMarkdown
                                children={skill.level5.level5}
                                remarkPlugins={[remarkGfm]}
                                components={{ p: 'span' }}
                              />
                            </p>
                            {skill.level6 && (
                              <p>
                                <span>Level 6:</span>{' '}
                                <ReactMarkdown
                                  children={skill.level6.level6}
                                  remarkPlugins={[remarkGfm]}
                                  components={{ p: 'span' }}
                                />
                              </p>
                            )}
                            {skill.level7 && (
                              <p>
                                <span>Level 7:</span>{' '}
                                <ReactMarkdown
                                  children={skill.level7.level7}
                                  remarkPlugins={[remarkGfm]}
                                  components={{ p: 'span' }}
                                />
                              </p>
                            )}
                            {skill.level8 && (
                              <p>
                                <span>Level 8:</span>{' '}
                                <ReactMarkdown
                                  children={skill.level8.level8}
                                  remarkPlugins={[remarkGfm]}
                                  components={{ p: 'span' }}
                                />
                              </p>
                            )}
                            {skill.level9 && (
                              <p>
                                <span>Level 9:</span>{' '}
                                <ReactMarkdown
                                  children={skill.level9.level9}
                                  remarkPlugins={[remarkGfm]}
                                  components={{ p: 'span' }}
                                />
                              </p>
                            )}
                            {skill.level10 && (
                              <p>
                                <span>Level 10:</span>{' '}
                                <ReactMarkdown
                                  children={skill.level10.level10}
                                  remarkPlugins={[remarkGfm]}
                                  components={{ p: 'span' }}
                                />
                              </p>
                            )}
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                  {employee.hasAlternium && (
                    <Col>
                      <div className="box">
                        <div className="skill-header">
                          <div
                            className={`skill-icon rarity-${employee.rarityClassName}`}
                          >
                            Alternium
                          </div>
                          <div className="skill-info">
                            <p className="skill-name">
                              {employee.alterniumInfo.name}
                            </p>
                          </div>
                        </div>
                        <div
                          className={`skill-description`}
                          dangerouslySetInnerHTML={{
                            __html: employee.alterniumInfo.description
                          }}
                        />
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${employee.rarityClassName}`}>
                    {employee.fullName}
                  </strong>{' '}
                  <strong>skills aren't available yet.</strong> They will be
                  added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header rarity-${employee.rarityClassName}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Stats
          </div>
          <div className="stats-section">
            {employeeStats ? (
              <>
                <div className="level-changer">
                  <div
                    className={`current-level rarity-${employee.rarityClassName}`}
                  >
                    Current level:{' '}
                    <span className="level">{statsLevel[0]}</span>
                  </div>
                  <div className="level-slider">
                    <Range
                      step={1}
                      min={1}
                      max={120}
                      values={statsLevel}
                      onChange={(values) => setStatsLevel(values)}
                      renderTrack={({ props, children }) => (
                        <div
                          role="button"
                          tabIndex={0}
                          onMouseDown={props.onMouseDown}
                          onTouchStart={props.onTouchStart}
                          style={{
                            ...props.style,
                            height: '36px',
                            display: 'flex',
                            width: '100%'
                          }}
                        >
                          <div
                            ref={props.ref}
                            style={{
                              height: '5px',
                              width: '100%',
                              borderRadius: '4px',
                              background: getTrackBackground({
                                values: statsLevel,
                                colors: [currentColor, '#484950'],
                                min: 1,
                                max: 120
                              }),
                              alignSelf: 'center'
                            }}
                          >
                            {children}
                          </div>
                        </div>
                      )}
                      renderThumb={({ props, isDragged }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: '27px',
                            width: '27px',
                            borderRadius: '5px',
                            backgroundColor: '#FFF',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <div
                            style={{
                              height: '16px',
                              width: '5px',
                              backgroundColor: isDragged ? '#009EEC' : '#484950'
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="stats-container">
                  <div className="stat-box">
                    <div className="stat">
                      {statsMode ? (
                        <>{employeeStats.pve.hp.toLocaleString()}</>
                      ) : (
                        <>{employeeStats.pvp.hp.toLocaleString()}</>
                      )}
                    </div>
                    <p className="stat-description">HP</p>
                  </div>
                  <div className="stat-box">
                    <div className="stat">
                      {statsMode ? (
                        <>{employeeStats.pve.atk.toLocaleString()}</>
                      ) : (
                        <>{employeeStats.pvp.atk.toLocaleString()}</>
                      )}
                    </div>
                    <p className="stat-description">ATK</p>
                  </div>
                  <div className="stat-box">
                    <div className="stat">
                      {statsMode ? (
                        <>{employeeStats.pve.def.toLocaleString()}</>
                      ) : (
                        <>{employeeStats.pvp.def.toLocaleString()}</>
                      )}
                    </div>
                    <p className="stat-description">DEF</p>
                  </div>
                  <div className="stat-box">
                    <div className="stat">
                      {statsMode ? (
                        <>{employeeStats.pve.critical.toLocaleString()}</>
                      ) : (
                        <>{employeeStats.pvp.critical.toLocaleString()}</>
                      )}
                    </div>
                    <p className="stat-description">CRIT</p>
                  </div>
                  <div className="stat-box">
                    <div className="stat">
                      {statsMode ? (
                        <>{employeeStats.pve.hit.toLocaleString()}</>
                      ) : (
                        <>{employeeStats.pvp.hit.toLocaleString()}</>
                      )}
                    </div>
                    <p className="stat-description">HIT</p>
                  </div>
                  <div className="stat-box">
                    <div className="stat">
                      {statsMode ? (
                        <>{employeeStats.pve.evade.toLocaleString()}</>
                      ) : (
                        <>{employeeStats.pvp.evade.toLocaleString()}</>
                      )}
                    </div>
                    <p className="stat-description">EVA</p>
                  </div>
                </div>
                <div className="options">
                  <div className="custom-switch">
                    <Switch
                      checked={statsMode}
                      onChange={() => setStatsMode(!statsMode)}
                      onColor="#009EEC"
                      offColor="#484950"
                      className="switch"
                    />
                    Show PVE stats
                  </div>
                  <div className="custom-switch">
                    <Switch
                      checked={statsIncludeLoyalty}
                      onChange={(value) => setStatsIncludeLoyalty(value)}
                      onColor="#009EEC"
                      offColor="#484950"
                      className="switch"
                    />
                    Include 100 loyalty bonus
                  </div>
                </div>
              </>
            ) : (
              <div className="info-box">
                <p>
                  <strong className={`rarity-${employee.rarityClassName}`}>
                    {employee.fullName}
                  </strong>{' '}
                  <strong>stats aren't available yet.</strong> They will be
                  added soon!
                </p>
              </div>
            )}
          </div>
          <div className={`content-header rarity-${employee.rarityClassName}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Availability
          </div>
          <Row xs={1} xxl={3} className="va-section">
            <Col>
              <div className="info-list-row">
                <div className="category">KR Release Date</div>
                <div className="details">
                  {employee.krReleaseDate ? employee.krReleaseDate : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">GLOBAL Release Date</div>
                <div className="details">
                  {employee.globalReleaseDate
                    ? employee.globalReleaseDate
                    : '-'}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className={`tab-inside ${currentTab === 'Review' ? 'active' : ''}`}
        >
          <div className={`mobile-header rarity-${employee.rarityClassName}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Review
            </p>
          </div>
          <div className={`content-header rarity-${employee.rarityClassName}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Ratings
          </div>
          {employee.newRatings.pve_early != '1' ? (
            <>
              <div className="detailed-ratings cs">
                <RatingBox
                  game="cs"
                  rating={employee.newRatings.pve_early}
                  ratingName="PVE (Early)"
                />
                <RatingBox
                  game="cs"
                  rating={employee.newRatings.pve_late}
                  ratingName="PVE (Late)"
                />
                <RatingBox
                  game="cs"
                  rating={employee.newRatings.pvp_sea}
                  ratingName="PVP"
                />
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${employee.rarityClassName}`}>
                    {employee.fullName}
                  </strong>{' '}
                  <strong>ratings aren't available yet.</strong> It will be
                  added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header rarity-${employee.rarityClassName}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Review
          </div>
          {!employee.isRatingPending ? (
            <div className="section-analysis">
              <div className={`review raw`}>
                {employee.reviewGeneral ? (
                  <>{renderRichText(employee.reviewGeneral, options)}</>
                ) : (
                  <div className="info-box">
                    <p>
                      <strong className={`rarity-${employee.rarityClassName}`}>
                        {employee.fullName}
                      </strong>{' '}
                      <strong>review isn't available yet.</strong> It will be
                      added soon!
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`rarity-${employee.rarityClassName}`}>
                  {employee.fullName}
                </strong>{' '}
                <strong>review isn't available yet.</strong> It will be added
                soon!
              </p>
            </div>
          )}
          <div className={`content-header rarity-${employee.rarityClassName}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
          </div>
          {!employee.isRatingPending && employee.pros ? (
            <div className="section-analysis">
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    {employee.pros && (
                      <div className="raw list">
                        {renderRichText(employee.pros, options)}
                      </div>
                    )}
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    {employee.cons && (
                      <div className="raw list">
                        {renderRichText(employee.cons, options)}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`rarity-${employee.rarityClassName}`}>
                  {employee.fullName}
                </strong>{' '}
                <strong>pros & cons aren't available yet.</strong> They will be
                added soon!
              </p>
            </div>
          )}
        </div>
        <div className={`tab-inside ${currentTab === 'Build' ? 'active' : ''}`}>
          <div className={`mobile-header rarity-${employee.rarityClassName}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Other information
            </p>
          </div>
          <div className={`content-header rarity-${employee.rarityClassName}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Gear recommendation
          </div>
          {employee.gearRecommendation ? (
            <Row className="gear-rec">
              <Col sm="12" md="6">
                <div className="box gear">
                  <h5>PVE gear</h5>
                  <p className="set">
                    Set:{' '}
                    {employee.gearRecommendation.pve.set && (
                      <span>{employee.gearRecommendation.pve.set}</span>
                    )}
                  </p>
                  {employee.gearRecommendation.pve.notes && (
                    <>
                      <hr />
                      <p className="notes">
                        <span>{employee.gearRecommendation.pve.notes}</span>
                      </p>
                    </>
                  )}
                </div>
              </Col>
              <Col sm="12" md="6">
                <div className="box gear">
                  <h5>PVP gear</h5>
                  <p className="set">
                    Set:{' '}
                    {employee.gearRecommendation.pvp.set && (
                      <span>{employee.gearRecommendation.pvp.set}</span>
                    )}
                  </p>
                  {employee.gearRecommendation.pvp.notes && (
                    <>
                      <hr />
                      <p className="notes">
                        <span>{employee.gearRecommendation.pvp.notes}</span>
                      </p>
                    </>
                  )}
                </div>
              </Col>
              <Col xs="12">
                <p className="gear-info">
                  The gear recommendations have been simplified and you should
                  use them in combination with{' '}
                  <Link to="/counter-side/guides/pvp-gearing">this guide</Link>{' '}
                  (for PVP) and{' '}
                  <Link to="/counter-side/guides/pve-gearing">this guide</Link>{' '}
                  (for PVE).
                </p>
              </Col>
            </Row>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`rarity-${employee.rarityClassName}`}>
                  {employee.fullName}
                </strong>{' '}
                <strong>gear recommendation aren't available yet.</strong> They
                will be added soon.
              </p>
            </div>
          )}
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </>
    </DashboardLayout>
  );
};

export default EmployeeDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const employee = data.currentUnit.nodes[0];

  return (
    <Seo
      title={employee.fullName + ' | Counter Side | Prydwen Institute'}
      description={
        employee.fullName +
        ' is a character from Counter Side. Find their skills, review and pros & cons here.'
      }
      image={employee.smallAvatar}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulEmployee(filter: { id: { eq: $contentfulId } }) {
      nodes {
        ...EmployeeWithSkillsFieldsFragment
      }
    }
  }
`;
