import { IEmployeeNode } from '../../../common/model/graphql-types';

interface IEmployeeStats {
  pve: {
    atk: number;
    critical: number;
    def: number;
    evade: number;
    hit: number;
    hp: number;
  };
  pvp: {
    atk: number;
    critical: number;
    def: number;
    evade: number;
    hit: number;
    hp: number;
  };
}

export const StatCalculator = (
  unitObj: IEmployeeNode,
  requiredLevel: number,
  includeLoyaltyBonus = false
): IEmployeeStats => {
  if (
    !unitObj ||
    !unitObj.stats ||
    !unitObj.stats.perLevel ||
    !unitObj.stats.base
  ) {
    return;
  }

  const multiplier = 1.3;
  let multiplier2 = 1;
  let multiplier3 = 1;
  if (requiredLevel >= 100) {
    multiplier2 =
      multiplier2 + Math.min(Math.ceil((requiredLevel - 100) / 2) * 0.02, 0.1);
  }
  if (requiredLevel >= 110) {
    multiplier3 =
      multiplier3 + Math.min(Math.ceil((requiredLevel - 110) / 2) * 0.02, 0.1);
  }

  let loyaltyMultiplier = 1;
  if (includeLoyaltyBonus) {
    loyaltyMultiplier = 1.02;
  }

  return {
    pve: {
      atk: Math.round(
        (unitObj.stats.base.atk +
          (requiredLevel - 1) * unitObj.stats.perLevel.atk) *
          multiplier *
          multiplier2 *
          multiplier3 *
          loyaltyMultiplier
      ),
      critical: Math.round(
        (unitObj.stats.base.critical +
          (requiredLevel - 1) * unitObj.stats.perLevel.critical) *
          multiplier *
          multiplier2 *
          multiplier3 *
          loyaltyMultiplier
      ),
      def: Math.round(
        (unitObj.stats.base.def +
          (requiredLevel - 1) * unitObj.stats.perLevel.def) *
          multiplier *
          multiplier2 *
          multiplier3 *
          loyaltyMultiplier
      ),
      evade: Math.round(
        (unitObj.stats.base.evade +
          (requiredLevel - 1) * unitObj.stats.perLevel.evade) *
          multiplier *
          multiplier2 *
          multiplier3 *
          loyaltyMultiplier
      ),
      hit: Math.round(
        (unitObj.stats.base.hit +
          (requiredLevel - 1) * unitObj.stats.perLevel.hit) *
          multiplier *
          multiplier2 *
          multiplier3 *
          loyaltyMultiplier
      ),
      hp: Math.round(
        (unitObj.stats.base.hp +
          (requiredLevel - 1) * unitObj.stats.perLevel.hp) *
          multiplier *
          multiplier2 *
          multiplier3 *
          loyaltyMultiplier
      )
    },
    pvp: {
      atk: Math.round(
        (unitObj.stats.base.atk +
          (requiredLevel - 1) * unitObj.stats.perLevel.atk * 0.1) *
          multiplier *
          multiplier2 *
          multiplier3 *
          loyaltyMultiplier
      ),
      critical: Math.round(
        (unitObj.stats.base.critical +
          (requiredLevel - 1) * unitObj.stats.perLevel.critical) *
          multiplier *
          multiplier2 *
          multiplier3 *
          loyaltyMultiplier
      ),
      def: Math.round(
        (unitObj.stats.base.def +
          (requiredLevel - 1) * unitObj.stats.perLevel.def * 0.1) *
          multiplier *
          multiplier2 *
          multiplier3 *
          loyaltyMultiplier
      ),
      evade: Math.round(
        (unitObj.stats.base.evade +
          (requiredLevel - 1) * unitObj.stats.perLevel.evade) *
          multiplier *
          multiplier2 *
          multiplier3 *
          loyaltyMultiplier
      ),
      hit: Math.round(
        (unitObj.stats.base.hit +
          (requiredLevel - 1) * unitObj.stats.perLevel.hit) *
          multiplier *
          multiplier2 *
          multiplier3 *
          loyaltyMultiplier
      ),
      hp: Math.round(
        (unitObj.stats.base.hp +
          (requiredLevel - 1) * unitObj.stats.perLevel.hp * 0.1) *
          multiplier *
          multiplier2 *
          multiplier3 *
          loyaltyMultiplier
      )
    }
  };
};
